export const styles = {
  container: {
    width: "100%",
    height: "100%",
  },
  mapWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    padding: 0,
    position: "relative",

    "& .gm-style .gm-style-iw-c": {
      border: "1px solid #E2E2FC !important",
      backgroundColor: "white !important",
      color: "#011533 !important",
      fontSize: "14px !important",
      lineHeight: "20px !important",
      padding: "8px 10px !important",

      "& .gm-style-iw-d": {
        overflow: "hidden !important",
      },

      "& > button": {
        display: "none !important",
      },
    },

    "& .gm-style-iw-tc": {
      display: "none",
    },
  },
  locationsLoading: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffff3d",
    position: "absolute",
    top: 0,
  },
};
