/* eslint-disable react/no-unknown-property */
import React, { memo } from "react";
import CloseIcon from "@material-ui/icons/Close";
import cn from "classnames";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = () => ({
  root: {
    margin: 0,
    "& .MuiPaper-root": {
      height: "444px",
      maxWidth: "632px",
    },
  },
  title: {
    color: "#00112B",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "34px",
    marginBottom: "12px",
  },
  textBlock: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#00112B",
    "& > div:first-child": {
      marginBottom: "16px",
    },
    marginBottom: "16px",
  },
  link: {
    color: "#EE4360",
  },
  formWrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  checkboxText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#526181",
  },
  checkbox: {
    marginLeft: "-11px",
    marginTop: "-8px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "34px",
  },
  buttonSubmit: {
    position: "relative",
    background: "#EE4360",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "208px",
    height: "48px",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  buttonTextSubmit: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  disabled: {
    background: "#C2C8D1",
    pointerEvents: "none",
    cursor: "default",
    color: "#FFFFFF",
    border: "none !important",
    "&:hover": {
      transform: "none",
    },
    "& div": {
      color: "#FFFFFF",
    },
  },
  buttonClose: {
    border: "1px solid #273754",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "208px",
    height: "48px",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  buttonTextClose: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#00112B",
  },
  progress: {
    position: "absolute",
    top: "14px",
    left: "16px",
  },
});

const DialogContent = withStyles(() => ({
  root: {
    padding: "0px 70px 38px 70px",
  },
}))(MuiDialogContent);

const Modal = ({ open, handleClose, classes, isChecked, setIsChecked, onSubmit, loading }) => {
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog onClose={handleClose} className={classes.root} aria-labelledby="customized-dialog-title" open={open}>
      <Box display={"flex"} justifyContent={"end"} style={{ height: "60px" }}>
        {!loading ? (
          <Button color="inherit" size="small" onClick={handleClose}>
            <CloseIcon fontSize="medium" />
          </Button>
        ) : null}
      </Box>
      <DialogContent className="mob-dialog-content">
        <div className={cn(classes.title, "mob-dialog-content__title")}>Ready to submit for review?</div>
        <div className={classes.textBlock}>
          <div>This takes no more than a few business hours! If we identify any problem, we will be in touch.</div>
        </div>
        <div className={classes.formWrapper}>
          <div className={classes.checkbox} cypress_id={"T&CCheckmark"}>
            <Checkbox checked={isChecked} onChange={handleChange} inputProps={{ "aria-label": "primary checkbox" }} />
          </div>
          <div cypress_id={"T&CText"}>
            I accept the{" "}
            <a href="https://www.oppizi.com/terms-of-service" target="_blank" className={classes.link} rel="noreferrer">
              terms of service
            </a>{" "}
            and agree to the potential inclusion of an internal tracking code in the final design of the flyer.
          </div>
        </div>
        <div className={cn(classes.buttonWrapper, "mob-btns-group-submit-pay")}>
          <div onClick={() => handleClose()} className={cn(classes.buttonClose, { [classes.disabled]: loading })}>
            <div className={classes.buttonTextClose} cypress_id={"goBackBtn"}>
              Cancel
            </div>
          </div>
          <div
            onClick={() => onSubmit()}
            className={cn(classes.buttonSubmit, { [classes.disabled]: !isChecked || loading })}
          >
            <div className={classes.buttonTextSubmit} cypress_id={"SubmitAndPayBtnInModal"}>
              {loading ? (isMobile ? "Loading..." : "Submit") : "Submit"}
            </div>
            {loading && !isMobile && <CircularProgress size={20} className={classes.progress} color="secondary" />}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(withStyles(styles)(Modal));
